import React, { useState } from 'react';
import { Button, Form, Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmSignUp, resendConfirmationCode } from '../services/cognitoAuth';

const ConfirmSignUp: React.FC = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    // Get the email from navigation state
    if (location.state && (location.state as any).email) {
      setEmail((location.state as any).email);
    } else {
      // If no email is provided, redirect to sign-up page
      navigate('/signup');
    }
  }, [location.state, navigate]);

  const handleConfirmSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      await confirmSignUp(email, verificationCode);
      alert('Your account has been confirmed. Please log in.');
      navigate('/');
    } catch (err: any) {
      setError(`Confirmation failed: ${err.message || 'Unknown error'}`);
    }
  };

  const handleResendCode = async () => {
    try {
      await resendConfirmationCode(email);
      alert('A new confirmation code has been sent to your email.');
    } catch (err: any) {
      setError(`Failed to resend code: ${err.message || 'Unknown error'}`);
    }
  };

  return (
    <Container>
      <h1>Confirm Sign Up</h1>
      <p>A confirmation code has been sent to your email: {email}</p>
      {error && <p className="text-danger">{error}</p>}
      <Form onSubmit={handleConfirmSignUp}>
        <Form.Group controlId="verificationCode">
          <Form.Label>Verification Code</Form.Label>
          <Form.Control
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Confirm
        </Button>
      </Form>
      <Button variant="link" onClick={handleResendCode}>
        Resend Confirmation Code
      </Button>
    </Container>
  );
};

export default ConfirmSignUp;
