import React, { useEffect, useRef, useState } from 'react';
import shaka from 'shaka-player';

interface VideoPlayerProps {
  manifestUrl: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ manifestUrl }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<shaka.Player | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCriticalError, setIsCriticalError] = useState<boolean>(false);
  const [textTracks, setTextTracks] = useState<shaka.extern.Track[]>([]);
  const [selectedTrackId, setSelectedTrackId] = useState<number | null>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const player = new shaka.Player(video);
    playerRef.current = player;

    // Attach an error event listener
    player.addEventListener('error', onError);

    // Load the manifest (DASH or HLS)
    player.load(manifestUrl).then(() => {
      // After the video is loaded, get the text tracks (subtitles)
      const tracks = player.getTextTracks();
      setTextTracks(tracks);
      
      // Enable the first subtitles track by default, if available
      if (tracks.length > 0) {
        player.selectTextTrack(tracks[0]);
        player.setTextTrackVisibility(true); // Ensure subtitles are visible
        setSelectedTrackId(tracks[0].id);
      }
    }).catch(onError);

    // Cleanup when the component unmounts
    return () => {
      player.destroy();
    };
  }, [manifestUrl]);

  const onError = (errorEvent: unknown) => {
    let errorMsg = 'An unknown error occurred.';
    let critical = true; // Assume the error is critical by default

    // Check if the errorEvent is a Shaka Player error
    if (errorEvent instanceof shaka.util.Error) {
      const shakaError = errorEvent as shaka.util.Error; // Cast errorEvent to shaka.util.Error

      // Map Shaka error codes to friendly messages
      errorMsg = mapShakaErrorToMessage(shakaError);

      // Determine if this error is critical (prevents playback)
      critical = isCriticalShakaError(shakaError.code);
    }

    // Log the error to the console
    console.error(errorMsg, errorEvent);

    // Update the state with the error message
    setErrorMessage(errorMsg);

    // Only show the overlay if the error is critical (blocks playback)
    setIsCriticalError(critical);
  };

  // Function to map Shaka error codes to friendly error messages
  const mapShakaErrorToMessage = (error: shaka.util.Error): string => {
    switch (error.code) {
      case 1000:
        return 'Network error: A request failed to load. Please check your internet connection.';
      case 1001:
        return 'Network error: The request was blocked or denied (403 Forbidden).';
      case 1002:
        return 'Network error: Unable to retrieve the manifest. (404 Not Found)';
      case 1003:
        return 'The video format is not supported by your device.';
      case 3016:
        return 'DRM error: License request was blocked or denied (403 Forbidden).';
      case 7002:
        return 'MSE error: Media cannot be appended. This may be caused by an unsupported format or a codec issue.';
      default:
        return `Shaka Player error: ${error.code}`;
    }
  };

  // Function to determine if the error is critical (i.e., prevents playback)
  const isCriticalShakaError = (errorCode: number): boolean => {
    // Non-critical errors (e.g., warnings that don't block playback)
    const nonCriticalErrors = [7002]; // You can extend this based on which errors you consider non-critical
    return !nonCriticalErrors.includes(errorCode);
  };

  const handleTrackSelection = (trackId: number) => {
    const player = playerRef.current;
    if (!player) return;

    const track = textTracks.find((t) => t.id === trackId);
    if (track) {
      player.selectTextTrack(track);
      player.setTextTrackVisibility(true); // Ensure subtitles are visible
      setSelectedTrackId(trackId);
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        maxWidth: '640px',
        backgroundColor: 'black', // Background color for the black borders
        overflow: 'hidden',
      }}
    >
      {/* Inner div for the 16:9 aspect ratio */}
      <div
        style={{
          position: 'relative',
          paddingTop: '56.25%', // 16:9 aspect ratio (9 / 16 = 0.5625 or 56.25%)
          backgroundColor: 'black', // Background color to fill any borders
        }}
      >
        <video
          ref={videoRef}
          controls
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'contain', // Ensures black bars are added if the video aspect ratio is different
            backgroundColor: 'black',
          }}
        ></video>
      </div>

      {isCriticalError && errorMessage && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '18px',
            fontWeight: 'bold',
            zIndex: 1,
          }}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;