import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../services/cognitoAuth'; // Ensure signIn is implemented correctly

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false); // Loading state to disable the button while submitting
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true); // Start loading when the form is submitted

    try {
      await signIn(email, password); // Call signIn from your cognitoAuth service
      navigate('/tasks'); // Navigate to the video list page after successful login
    } catch (err: any) {
      // Handle specific error codes if needed
      if (err.code === 'UserNotFoundException') {
        setError('User does not exist. Please check your email or sign up.');
      } else if (err.code === 'NotAuthorizedException') {
        setError('Incorrect email or password.');
      } else {
        setError('Login failed. Please try again.');
      }
      console.error('Login error:', err); // Log error for debugging
    } finally {
      setLoading(false); // Stop loading once the request finishes
    }
  };

  return (
    <Container className="position-relative">
      <img src="/logo_light_512.png" alt="Logo" className="logo-absolute" width="150" height="150" />

      {/* Centered Login heading */}
      <Row className="justify-content-center mb-4">
        <Col xs="auto">
          <h1 className="mb-0 text-center">Login</h1>
        </Col>
      </Row>

      {error && <p className="text-danger">{error}</p>}
      <Form onSubmit={handleLogin}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required // Ensure email field is required
          />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required // Ensure password field is required
            minLength={8} // Optional: Add minimum length for password
          />
        </Form.Group>

        <Button variant="dark" type="submit" disabled={loading} className="mt-2 mb-1">
          {loading ? 'Logging in...' : 'Login'} {/* Show a loading message while the form is submitting */}
        </Button>
      </Form>
      <p>
        Don't have an account? <a href="/signup" style={{ color: 'red' }}>Sign Up</a>
      </p>
    </Container>
  );
};

export default Login;
