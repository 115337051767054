// src/components/SignUp.tsx

import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { signUp } from '../services/cognitoAuth';

const SignUp: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false); // State for terms acceptance
  const navigate = useNavigate();

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!acceptedTerms) {
      setError('You must accept the Terms of Service and Privacy Policy to sign up.');
      return;
    }

    try {
      await signUp(email, password);
      // Redirect to the confirmation page
      navigate('/admin-approval-pending', { state: { email } });
    } catch (err: any) {
      setError(`Sign-up failed: ${err.message || 'Unknown error'}`);
    }
  };

  return (
    <Container>
      <img src="/logo_light_512.png" alt="Logo" className="logo-absolute" width="150" height="150" />

      <Row className="justify-content-center mb-4">
        <Col xs="auto">
          <h1 className="mb-0 text-center">Sign Up</h1>
        </Col>
      </Row>

      {error && <p className="text-danger">{error}</p>}
      <Form onSubmit={handleSignUp}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            minLength={8}
          />
        </Form.Group>

        {/* Terms of Service and Privacy Policy */}
        <Form.Group controlId="terms" className="mt-3">
          <Form.Check
            type="checkbox"
            label={
              <>
                I have read and agree to the{' '}
                <a href="https://raptvideo.com/terms-of-service/"  style={{ color: 'red' }} target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href="https://raptvideo.com/privacy-policy/"  style={{ color: 'red' }} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>.
              </>
            }
            checked={acceptedTerms}
            onChange={(e) => setAcceptedTerms(e.target.checked)}
          />
        </Form.Group>

        <Button variant="dark" type="submit" className="mt-2 mb-1">
          Sign Up
        </Button>
      </Form>
      <p>
        Already have an account? <a href="/" style={{ color: 'red' }}>Login</a>
      </p>
    </Container>
  );
};

export default SignUp;
