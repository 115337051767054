import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateTask from './components/CreateTask';
import VideoTask from './components/VideoTask';
import TaskList from './components/TaskList';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ConfirmSignUp from './components/ConfirmSignUp';
import AdminApprovalPending from './components/AdminApprovalPending';
import PrivateRoute from './services/PrivateRoute'; // If you're using PrivateRoute for protected pages

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/confirm-signup" element={<ConfirmSignUp />} />
          <Route path="/admin-approval-pending" element={<AdminApprovalPending />} />

          {/* Protected Routes */}
          <Route path="/tasks" element={<PrivateRoute component={TaskList} />} />
          <Route path="/create-task" element={<PrivateRoute component={CreateTask} />} />
          <Route path="/video-task/:id" element={<PrivateRoute component={VideoTask} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
