import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession,
    ISignUpResult,
    CognitoUserAttribute,
  } from 'amazon-cognito-identity-js';
  import { awsCognitoConfig } from '../aws-cognito-config';
  
  // Initialize Cognito User Pool
  const userPool = new CognitoUserPool({
    UserPoolId: awsCognitoConfig.userPoolId,
    ClientId: awsCognitoConfig.clientId,
  });

  // Function to fetch the apiKey from API Gateway and save it in local storage
export const fetchAndStoreApiKey = async (idToken: string): Promise<void> => {
  const apiUrl = 'https://euas1ozeve.execute-api.eu-west-1.amazonaws.com/Prod/apikey'; // Replace with your actual API Gateway URL

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${idToken}`, // Pass the ID token in the Authorization header
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching apiKey: ${response.statusText}`);
    }

    const data = await response.json(); // Parse the response JSON
    const apiKey = data.apiKey;

    if (!apiKey) {
      throw new Error('API Key not found in the response.');
    }

    // Save the apiKey to local storage
    localStorage.setItem('apiKey', apiKey);

    console.log('apiKey saved to local storage:', apiKey);
  } catch (error) {
    console.error('Error fetching apiKey:', error);
    throw error;
  }
};

  
  // **Sign-up function**
  export const signUp = (
    email: string,
    password: string
  ): Promise<ISignUpResult> => {
    const attributeList: CognitoUserAttribute[] = [];
  
    // Optionally add user attributes
    const attributeEmail = new CognitoUserAttribute({ Name: 'email', Value: email });
    attributeList.push(attributeEmail);
  
    return new Promise((resolve, reject) => {
      userPool.signUp(
        email,
        password,
        attributeList,
        [], // Validation data
        (err?: Error, result?: ISignUpResult) => {
          if (err) {
            return reject(err);
          }
          resolve(result!);
        }
      );
    });
  };
  
  // **Confirm sign-up function**
  export const confirmSignUp = (email: string, code: string): Promise<void> => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
  
    return new Promise((resolve, reject) => {
      user.confirmRegistration(code, true, (err?: Error, result?: string) => {
        if (err) {
          return reject(err);
        }
        resolve();
      });
    });
  };
  
  // **Resend confirmation code function**
  export const resendConfirmationCode = (email: string): Promise<void> => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
  
    return new Promise((resolve, reject) => {
      user.resendConfirmationCode((err?: Error, result?: string) => {
        if (err) {
          return reject(err);
        }
        resolve();
      });
    });
  };
  
  // **Sign-in function**
  export const signIn = (
    email: string,
    password: string
  ): Promise<CognitoUserSession> => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
  
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
  
    return new Promise((resolve, reject) => {
      user.authenticateUser(authenticationDetails, {
        onSuccess: async (result: CognitoUserSession) => {
          const idToken = result.getIdToken().getJwtToken(); // Extract the ID token from the session

          try {
            // Fetch the apiKey after successful login
            await fetchAndStoreApiKey(idToken);
            resolve(result); // Resolve the session result after fetching apiKey
          } catch (error) {
            reject(error); // Handle errors if fetching apiKey fails
          }
        },
        onFailure: (err: any) => {
          reject(err);
        },
      });
    });
  };
  
  // **Get current session for the logged-in user**
  export const getCurrentSession = (): Promise<CognitoUserSession> => {
    const user = userPool.getCurrentUser();
  
    if (!user) {
      return Promise.reject('No user is currently logged in');
    }
  
    return new Promise((resolve, reject) => {
      user.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err || !session) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  };
  