import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AdminApprovalPending: React.FC = () => {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate('/'); // Redirect to the login page
  };

  return (
    <Container>
      <img src="/logo_light_512.png" alt="Logo" className="logo" width="150" height="150" onClick={handleGoToLogin}/>

      <div className="text-center mt-5">
        <h1>Account Pending Admin Approval</h1>
        <p>
            Thank you for signing up! Your account requires administrator approval
            before it can be activated. Once your account has been approved, you will
            receive an email notification.
        </p>
        <Button  variant="dark" type="submit" className="mt-2 mb-1" onClick={handleGoToLogin}>
            Go to Login Page
        </Button>
      </div>
    </Container>
  );
};

export default AdminApprovalPending;
