import React, { useState, useEffect } from 'react';
import { Container, Button, Tooltip, OverlayTrigger, Accordion } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { differenceInSeconds } from 'date-fns'; // To calculate duration in seconds
import { apiKey, apiBaseUrl } from '../config/apiConfig';
import VideoPlayer from './VideoPlayer';

const VideoTask: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Get the task ID from the route params
  const [taskStatus, setTaskStatus] = useState('Loading...'); // Initial status
  const [taskDetails, setTaskDetails] = useState<any>(null); // Store the task details
  const [errorDetails, setErrorDetails] = useState<{ error?: string; cause?: string } | null>(null);
  const [content, setContent] = useState<{ [key: string]: string | null }>({}); // Store fetched content for filters
  const [contentError, setContentError] = useState<{ [key: string]: string | null }>({}); // Store errors for content fetching
  const [fetchedFilters, setFetchedFilters] = useState<{ [key: string]: boolean }>({}); // Track which filters have been fetched
  const navigate = useNavigate();

  useEffect(() => {
    console.log(`apy key is ${apiKey}`)
    const fetchTaskStatus = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/task/${id}`, {
          method: 'GET',
          headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching task status: ${response.statusText}`);
        }

        const data = await response.json();
        const status = data.status?.S; // Get the status from the response

        setTaskStatus(status); // Update the task status
        setTaskDetails(data); // Update task details

        // If the task has failed, capture the error details
        if (status === 'FAILED') {
          setErrorDetails({
            error: data.error?.S || 'Unknown error',
            cause: data.cause?.S || 'No specific cause provided',
          });
        }
      } catch (error) {
        console.error('Error fetching task details:', error);
      }
    };

    // Fetch the task status initially
    fetchTaskStatus();

    // Poll the task status every 5 seconds
    const intervalId = setInterval(() => {
      fetchTaskStatus();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [id]);

  // Generalized function to fetch content for a filter type and file path
  const fetchContentForFilter = async (filterType: string, filePath: string) => {
    try {
      if (taskDetails) {
        const parsedRequest = taskDetails?.request?.S ? JSON.parse(taskDetails.request.S) : null;
        const outputBucket = parsedRequest?.output?.bucket;
        const outputPath = parsedRequest?.output?.path;

        if (outputBucket && outputPath) {
          const url = `https://cdn.raptvideo.com/${outputPath}/${filePath}`;
          const response = await fetch(url);

          if (!response.ok) {
            throw new Error(`Failed to fetch ${filterType} content: ${response.statusText}`);
          }

          const content = await response.text();
          setContent((prevContent) => ({
            ...prevContent,
            [filterType]: content,
          }));
        }
      }
    } catch (error) {
      const errorMessage = (error as Error).message || 'An unknown error occurred'; // Ensure error is an Error
      setContentError((prevError) => ({
        ...prevError,
        [filterType]: `Error fetching ${filterType} content: ${errorMessage}`,
      }));
    }
  };

  // Utility function to calculate duration between two timestamps in seconds
  const calculateDuration = (start: string, end: string) => {
    return differenceInSeconds(new Date(end), new Date(start));
  };

  // Utility function to format the duration into human-readable format
  const formatDuration = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let formattedDuration = '';

    if (hours > 0) {
      formattedDuration += `${hours} hour${hours > 1 ? 's' : ''} `;
    }
    if (minutes > 0) {
      formattedDuration += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    }
    if (remainingSeconds > 0 || seconds < 60) {
      formattedDuration += `${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
    }

    return formattedDuration.trim(); // Remove extra spaces
  };

  // Dynamically create a list of phases based on available timestamps
  const getPhases = (): { label: string, start: string, end: string | undefined }[] => {
    // Define the type for the phases array explicitly
    type Phase = {
      label: string;
      timestamp: string;
    };
  
    // Collect all timestamps with their associated phases
    const allPhases: Phase[] = [];
  
    if (taskDetails?.createdAt?.S) {
      allPhases.push({
        label: 'Created',
        timestamp: taskDetails.createdAt.S
      });
    }

    if (taskDetails?.analyzingAt?.S) {
      allPhases.push({
        label: 'Analyzing',
        timestamp: taskDetails.analyzingAt.S
      });
    }
  
    if (taskDetails?.analyzingFilterAt?.S) {
      allPhases.push({
        label: 'Analyzing Filter',
        timestamp: taskDetails.analyzingFilterAt.S
      });
    }
  
    if (taskDetails?.encodingAt?.S) {
      allPhases.push({
        label: 'Encoding',
        timestamp: taskDetails.encodingAt.S
      });
    }
  
    if (taskDetails?.muxingAt?.S) {
      allPhases.push({
        label: 'Muxing',
        timestamp: taskDetails.muxingAt.S
      });
    }
  
    if (taskDetails?.muxingFilterAt?.S) {
      allPhases.push({
        label: 'Muxing Filter',
        timestamp: taskDetails.muxingFilterAt.S
      });
    }

    if (taskDetails?.endedAt?.S) {
      allPhases.push({
        label: 'Finished',
        timestamp: taskDetails.endedAt.S
      });
    }
  
    // Sort the phases by their timestamp in chronological order
    allPhases.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
  
    // Now, build each phase, connecting them by making the end of each phase the start of the next
    const phasesWithEnd = allPhases.map((phase, index) => {
      // Set the end of the current phase to be the start of the next phase, if it exists
      const nextPhase = allPhases[index + 1];
      return {
        label: phase.label,
        start: phase.timestamp,
        end: nextPhase ? nextPhase.timestamp : taskDetails?.endedAt?.S || undefined
      };
    });
  
    return phasesWithEnd;
  };

  // Render a phase as part of the progress bar
  const renderPhaseBar = (label: string, start: string | undefined, end: string | undefined) => {
    if (!start && !end) return null;


    const endTime = end || new Date().toISOString(); // Use current time if the phase is still in progress
    const totalDuration = calculateDuration(taskDetails?.createdAt?.S, taskDetails?.endedAt?.S || new Date().toISOString()) - 1; // Total time
    const phaseDuration = start && endTime ? calculateDuration(start, endTime) : 0;

    let widthPercentage = totalDuration ? (phaseDuration / totalDuration) * 100 : 0;
  
    let color = '#DC143C';
    if (label === 'Created' || label === 'Finished') {
      color = 'black';
      widthPercentage = 0.5;
    }

    if (label === 'Created') end = undefined;
    if (label === 'Finished') start = undefined;

    let displayContent;
    if (widthPercentage >= 15) {
      displayContent = (
        <>
          <strong>{label}</strong>
          <span style={{ marginLeft: '4px' }}>{formatDuration(phaseDuration)}</span>
        </>
      );
    } else if (widthPercentage >= 2) {
      displayContent = <strong>{label.charAt(0)}</strong>;
    } else {
      displayContent = null;
    }

    return (
      <div
        style={{
          width: `${widthPercentage}%`,
          backgroundColor: color,
          height: '30px',
          position: 'relative',
          borderRight: label === 'Finished' ? 'none' : '3px solid white',
        }}
      >
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${label}`}>
              {<div>{label}:</div>}
              {start && <div>Start: {new Date(start).toLocaleString()}</div>}
              {end && <div>End: {new Date(end).toLocaleString()}</div>}
              {start && end && <div>Duration: {formatDuration(phaseDuration)}</div>}
            </Tooltip>
          }
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '0.8rem',
              color: 'white',
              padding: '0 5px',
              height: '100%',
            }}
          >
            {displayContent}
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  // Function to handle fetching the content when the accordion is expanded
  const handleFetchContent = (filterType: string, filePath: string) => {
    if (!fetchedFilters[filterType]) {
      fetchContentForFilter(filterType, filePath);
      setFetchedFilters((prev) => ({
        ...prev,
        [filterType]: true,
      }));
    }
  };

  const handleBackToList = () => {
    navigate('/tasks'); // Navigate back to the task list
  };

  // Parse the initial request
  const parsedRequest = taskDetails?.request?.S ? JSON.parse(taskDetails.request.S) : null;

  // Generate links for progressive muxing
  const generateProgressiveLinks = (outputBucket: string, outputPath: string, encodings: any[]) => {
    return encodings
      .filter((encoding) => encoding.width && encoding.bitrateKb && encoding.fps)
      .map((encoding, index) => {
        const fileName = `output_${encoding.width}_${encoding.bitrateKb * 1000}_${encoding.fps}.mp4`;
        const fileUrl = `https://cdn.raptvideo.com/${outputPath}/${fileName}`;
        return (
          <li key={index}>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              {fileName}
            </a>
          </li>
        );
      });
  };

  const getCurrentDuration = () => {
    let phases = getPhases();
    let duration = 0;
    
    if (phases.length > 0) {
      let start = phases[0].start;
      let end = phases[phases.length - 1].end || new Date().toISOString(); // Get the last phase's end or use current time
      duration = calculateDuration(start, end);
    }
  
    return duration;
  };

  return (
    <Container>
      <img src="/logo_light_512.png" alt="Logo" className="logo" width="150" height="150" onClick={handleBackToList}/>

      <h1 className="text-center">Video Task:</h1>
      <h4 className="text-center">{id}</h4>
      <p className="text-center mb-1">Status: {taskStatus}</p>
      <p className="text-center">Duration: {formatDuration(getCurrentDuration())}</p>

      {taskStatus === 'FAILED' && errorDetails && (
        <div className="alert alert-danger">
          <h4>Task Failed</h4>
          <p><strong>Error:</strong> {errorDetails.error}</p>
          <p><strong>Cause:</strong> {errorDetails.cause}</p>
        </div>
      )}

      <div className="progress-container" style={{ display: 'flex', width: '100%', marginBottom: '20px', borderRadius: '5px' }}>
        {getPhases().map((phase) => renderPhaseBar(phase.label, phase.start, phase.end))}
      </div>

      <div>
          <h3>Input:</h3>
      </div>

      <Accordion className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>View Request</Accordion.Header>
          <Accordion.Body>
            <pre>{parsedRequest ? JSON.stringify(parsedRequest, null, 2) : 'No request data available'}</pre>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Display the link and player if task is finished */}
      {taskStatus === 'FINISHED' && parsedRequest && (
        <>
        <div>
            <h3>Output:</h3>
        </div>

        {/* Transcription section */}
        {parsedRequest?.filters?.find((filter: any) => filter.type === 'transcription' && filter.export === true) &&
          taskDetails?.endedAt?.S && (
            <div className="mt-3">
              <h5>Transcription:</h5>
              <Accordion className="mt-3">
                <Accordion.Item eventKey="1">
                  <Accordion.Header onClick={() => handleFetchContent('transcription', 'transcription/transcription.vtt')}>
                    View Transcription
                  </Accordion.Header>
                  <Accordion.Body>
                    {contentError['transcription'] ? (
                      <p className="text-danger">{contentError['transcription']}</p>
                    ) : content['transcription'] ? (
                      <pre>{content['transcription']}</pre>
                    ) : (
                      <p>Loading transcription...</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <a
                href={`https://cdn.raptvideo.com/${parsedRequest.output.path}/transcription/transcription.vtt`}
                style={{ color: 'black', textDecoration: 'underline' }}
                className="mt-3 mb-3"
                download
              >
                Download Transcription
              </a>
            </div>
          )
        }

        {/* Detect Scenes section */}
        {parsedRequest?.filters?.find((filter: any) => filter.type === 'scene-detection' && filter.export === true) &&
          taskDetails?.endedAt?.S && (
            <div className="mt-3">
              <h5>Scene Detection:</h5>
              <Accordion className="mt-3">
                <Accordion.Item eventKey="2">
                  <Accordion.Header onClick={() => handleFetchContent('scene-detection', 'scene-detection/scene-detection.txt')}>
                    View Scene Detection
                  </Accordion.Header>
                  <Accordion.Body>
                    {contentError['scene-detection'] ? (
                      <p className="text-danger">{contentError['scene-detection']}</p>
                    ) : content['scene-detection'] ? (
                      <pre>{content['scene-detection']}</pre>
                    ) : (
                      <p>Loading scene detection...</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <a
                href={`https://cdn.raptvideo.com/${parsedRequest.output.path}/scene-detection/scene-detection.txt`}
                style={{ color: 'black', textDecoration: 'underline' }}
                className="mt-3 mb-3"
                download
              >
                Download Scene Detection
              </a>

            </div>
          )
        }

        {parsedRequest?.filters?.find((filter: any) => filter.type === 'face-recognition' && filter.export === true) &&
          taskDetails?.endedAt?.S && (
            <div className="mt-3">
              <h5>Face Recognition:</h5>
              <Accordion className="mt-3">
                <Accordion.Item eventKey="2">
                  <Accordion.Header onClick={() => handleFetchContent('face-recognition', 'face-recognition/face-recognition.txt')}>
                    View Face Recognition
                  </Accordion.Header>
                  <Accordion.Body>
                    {contentError['face-recognition'] ? (
                      <p className="text-danger">{contentError['face-recognition']}</p>
                    ) : content['face-recognition'] ? (
                      <pre>{content['face-recognition']}</pre>
                    ) : (
                      <p>Loading face recognition...</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <a
                href={`https://cdn.raptvideo.com/${parsedRequest.output.path}/face-recognition/face-recognition.txt`}
                style={{ color: 'black', textDecoration: 'underline' }}
                className="mt-3 mb-3"
                download
              >
                Download Face Recognition
              </a>
            </div>
          )
        }

        {parsedRequest?.filters?.find((filter: any) => filter.type === 'video-captioning' && filter.export === true) &&
          taskDetails?.endedAt?.S && (
            <div className="mt-3">
              <h5>Video Captioning:</h5>
              <Accordion className="mt-3">
                <Accordion.Item eventKey="2">
                  <Accordion.Header onClick={() => handleFetchContent('video-captioning', 'video-captioning/video-captioning.txt')}>
                    View Video Captioning
                  </Accordion.Header>
                  <Accordion.Body>
                    {contentError['video-captioning'] ? (
                      <p className="text-danger">{contentError['video-captioning']}</p>
                    ) : content['video-captioning'] ? (
                      <pre>{content['video-captioning']}</pre>
                    ) : (
                      <p>Loading video captioning...</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <a
                href={`https://cdn.raptvideo.com/${parsedRequest.output.path}/video-captioning/video-captioning.txt`}
                style={{ color: 'black', textDecoration: 'underline' }}
                className="mt-3 mb-3"
                download
              >
                Download Video Captioning
              </a>
            </div>
          )
        }

        {parsedRequest.muxing.type === 'progressive' ? (
          <div className="mt-3">
            <h5>Available Video Files:</h5>
            <ul>
              {generateProgressiveLinks(
                parsedRequest.output.bucket,
                parsedRequest.output.path,
                parsedRequest.encoding
              )}
            </ul>
          </div>
        ) : parsedRequest.muxing.type === 'dash' ? (
          <div className="mt-3">
            <h5>DASH Manifest:</h5>
            <a
              href={`https://cdn.raptvideo.com/${parsedRequest.output.path}/manifest/dash.mpd`}
              style={{ color: 'black', textDecoration: 'underline' }}
              className="mt-3 mb-3"
              download
            >
              Download Dash Manifest
            </a>

            {/* Shaka Player */}
            <div className="mt-1">
              <VideoPlayer manifestUrl={`https://cdn.raptvideo.com/${parsedRequest.output.path}/manifest/dash.mpd`} />
            </div>
          </div>
        ) : null}
        </>
      )}

      <Button variant="secondary" onClick={handleBackToList} className="mt-3 mb-3">
        Back
      </Button>
    </Container>
  );
};

export default VideoTask;
