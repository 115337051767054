import React, { useEffect, useState } from 'react';
import { Button, Table, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { apiKey, apiBaseUrl } from '../config/apiConfig';

interface Task {
  id: string;
  status: string;
  request: string;
  createdAt: string;
  endedAt?: string;
  duration?: string;
}

const TaskList: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/tasks`, {
          method: 'GET',
          headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching tasks: ${response.statusText}`);
        }

        const data = await response.json();

        // Parse the DynamoDB JSON format to standard JSON
        const parsedTasks = parseDynamoDBResponse(data);

        // Sort tasks by createdAt in descending order
        const sortedTasks = parsedTasks.sort((a: Task, b: Task) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        setTasks(sortedTasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, []);

  // Utility function to parse DynamoDB JSON and calculate duration
  const parseDynamoDBResponse = (data: any): Task[] => {
    const { Items } = data;
    if (!Items) return [];

    return Items.map((item: any) => {
      const createdAt = item.createdAt?.S ? new Date(item.createdAt.S).toISOString() : '';
      const endedAt = item.endedAt?.S ? new Date(item.endedAt.S).toISOString() : '';
      let duration = null;

      if (createdAt && endedAt) {
        const diffInSeconds = Math.floor((new Date(endedAt).getTime() - new Date(createdAt).getTime()) / 1000);
  
        const hours = Math.floor(diffInSeconds / 3600);
        const minutes = Math.floor((diffInSeconds % 3600) / 60);
        const seconds = diffInSeconds % 60;
  
        duration = `${hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''} ` : ''}${
          minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''} ` : ''
        }${seconds} second${seconds !== 1 ? 's' : ''}`;
      }

      return {
        id: item.id?.S || '',
        status: item.status?.S || '',
        request: item.request?.S || '',
        createdAt: createdAt || '',
        endedAt: endedAt || '',
        duration: duration || 'N/A',
      };
    });
  };

  return (
    <>
     <Container className="position-relative">
        <img src="/logo_light_512.png" alt="Logo" className="logo" width="150" height="150" />

        <Row className="mb-3 mt-0">
          <Col className="text-center">
            <h1 className="mb-0">Video Tasks</h1>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col className="text-end">
            <Link to={`/create-task`}>
              <Button variant="danger">New Task</Button>
            </Link>
          </Col>
        </Row>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Request</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Duration</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tasks.length > 0 ? (
              tasks.map((task) => (
                <tr key={task.id}>
                  <td data-label="ID">{task.id}</td>
                  <td data-label="Request">
                    <div className="request-content">{task.request}</div>
                  </td>
                  <td data-label="Status">{task.status}</td>
                  <td data-label="Created At">{new Date(task.createdAt).toLocaleString()}</td>
                  <td data-label="Duration">{task.duration}</td>
                  <td data-label="Action">
                    <Link to={`/video-task/${task.id}`}>
                      <Button variant="dark">View details</Button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No tasks found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Create Task Button at the Bottom */}
        <div className="d-flex justify-content-end mb-3">
          <Link to={`/create-task`}>
          <Button variant="danger">New Task</Button>
          </Link>
        </div>
      </Container>
    </>
  );
};

export default TaskList;
