import React, { useState, useEffect } from 'react';
import {
  Container,
  Form,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Row,
  Col
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { apiKey, apiBaseUrl } from '../config/apiConfig';

type SampleOption = 'TearsOfSteel.mov' | 'Sintel.mkv' | 'BigBuckBunny.mp4' | 'FaceRecognition-Room-Work-Leave-Drop-Light.mov' | 'FaceRecognition-PersonInRoom-Swap.mov' | 'FaceRecognition-PersonInRoom-Swap-2Person.mov' | 'FaceRecognition-LongVideo.mov';
type MuxingFormat = 'progressive' | 'dash' | 'hls' | 'dash&hls';
type CodecOption = 'h264' | 'h265' | 'vp9' | 'av1';
type InputMode = 'Copy' | 'None' | 'External';
type InputType = 'Ingest' | 'Storage' | 'S3';
type InputMethod = 'Sample' | 'Storage' | 'S3';

interface BitrateLadder {
  bitrate: number;
  width?: number;
  height?: number;
  framerate?: number;
}

const CreateTask: React.FC = () => {
    const navigate = useNavigate();
    const [mediaFilter, setMediaFilter] = useState({
        transcription: false,
        sceneDetection: false,
        faceRecognition: false,
        videoCaptioning: false,
        scriptExtraction: false,
        audioSummarization: false,
        videoSummarization: false,
        imageOverlay: false,
        timeOverlay: false,
        personOverlay: false
    });
    const [mediaProbe, setMediaProbe] = useState<boolean>(false);
    const [muxingFormat, setMuxingFormat] = useState<MuxingFormat>('dash');
    const [codec, setCodec] = useState<CodecOption>('h264');
    const [bitrateLadder, setBitrateLadder] = useState<BitrateLadder[]>([
        { bitrate: 8000, width: 1920, framerate: 24 },
        { bitrate: 6000, width: 1920, framerate: 24 },
        { bitrate: 3000, width: 1280, framerate: 24 },
        { bitrate: 2000, width: 960, framerate: 24 },
        { bitrate: 1100, width: 768, framerate: 24 },
        { bitrate: 500, width: 640, framerate: 24 },
        { bitrate: 200, width: 416, framerate: 24 }
    ]);
    const [inputSettings, setInputSettings] = useState({
        inputMethod: 'Sample' as InputMethod,
        sampleFile: 'TearsOfSteel.mov' as SampleOption,
        S3Bucket: '',
        S3File: '',
    });
    const [audioSettings, setAudioSettings] = useState({
        inputMode: 'Copy' as InputMode,
        inputType: null as InputType | null,
        externalValue: '',
        externalS3Bucket: '',
        externalS3File: '',
    });
    const [subtitleSettings, setSubtitleSettings] = useState({
        inputMode: 'Copy' as InputMode,
        inputType: null as InputType | null,
        externalValue: '',
        externalS3Bucket: '',
        externalS3File: '',
    });
    const [jsonData, setJsonData] = useState<any>({});
    
    const handleToggleChange = (key: keyof typeof mediaFilter, value: boolean) => {
        if ((key == 'faceRecognition' || key == 'videoCaptioning') && value == true) {
            handleToggleChange('sceneDetection', true)
        }
        if (key == 'sceneDetection' && value == false) {
            handleToggleChange('faceRecognition', false)
            handleToggleChange('videoCaptioning', false)
        }
        if (key == 'personOverlay' && value == true) {
            handleToggleChange('faceRecognition', true)
        }
        if (key == 'faceRecognition' && value == false) {
            handleToggleChange('personOverlay', false)
        }
        setMediaFilter((prev) => ({ ...prev, [key]: value }));
    };
    
    const handleMuxingFormatChange = (val: MuxingFormat) => setMuxingFormat(val);
    const handleCodecChange = (val: CodecOption) => setCodec(val);
    
    const addBitrateLadder = () => {
        setBitrateLadder([...bitrateLadder, { bitrate: 1000 }]);
    };
    
    const removeBitrateLadder = (index: number) => {
        const newLadder = bitrateLadder.filter((_, i) => i !== index);
        setBitrateLadder(newLadder);
    };
    
    const handleBitrateLadderChange = (index: number, field: keyof BitrateLadder, value: string | number) => {
        const updatedLadder = [...bitrateLadder];
        
        const parsedValue = value === '' ? undefined : field === 'framerate' ? parseFloat(value as string) : parseInt(value as string);
    
        if (parsedValue !== undefined && !isNaN(parsedValue)) {
        updatedLadder[index][field] = parsedValue;
        } else if (field === 'bitrate') {
        updatedLadder[index][field] = 100;
        }
    
        setBitrateLadder(updatedLadder);
    };
    
    const updateInputSettings = (field: keyof typeof inputSettings, value: any) => {
        setInputSettings((prevState) => ({
        ...prevState,
        [field]: value,
        }));
    };
    

    const updateAudioSetting = (field: keyof typeof audioSettings, value: any) => {
        setAudioSettings((prevState) => ({
        ...prevState,
        [field]: value,
        }));
    };
    
    const updateSubtitleSetting = (field: keyof typeof subtitleSettings, value: any) => {
        setSubtitleSettings((prevState) => ({
        ...prevState,
        [field]: value,
        }));
    };
    
    const postData = async (url: string, data: any, apiKey: string) => {
        try {
        const jsonString = JSON.stringify(jsonData);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Content-Length': jsonString.length.toString(),
            'Host': 'dashboard.raptvideo.com',
            'x-api-key': apiKey
            },
            body: jsonString
        });
    
        const responseData = await response.json();
        console.log('Response:', responseData);
    
        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${responseData.message}`);
        }
    
        return responseData;
        } catch (error) {
        console.error('Error during POST request:', error);
        throw error;
        }
    };
    
    const handleSubmit = () => {
        postData(apiBaseUrl + '/task', jsonData, apiKey)
          .then(response => {
            // Assuming the response contains the taskId in the shape of the JSON you provided
            const taskId = response.taskId;
            console.log('POST request successful:', response);
      
            // Navigate to the video task details page using the taskId
            navigate(`/video-task/${taskId}`);
          })
          .catch(error => {
            console.error('POST request failed:', error);
            alert('POST request failed: ' + error);
          });
      };
    
    const handleBackClick = () => {
        navigate('/tasks'); // Adjust the path to match your task list route
    };

    useEffect(() => {
    
        let input : {
            bucket?: string;
            file: string;
          } = { file: '' }; 

        if (inputSettings.inputMethod === "Sample") {
            const sampleFile = inputSettings.sampleFile
            const [video_name, extension] = sampleFile.split('.')

            input = {
                bucket: 'raptvideo-eu-west-1-test',
                file: sampleFile.startsWith('FaceRecognition')
                  ? `TestFiles/FaceRecognition/${sampleFile}`
                  : `TestFiles/${video_name}/${video_name}_FullHD.${extension}`
              }
        } else if (inputSettings.inputMethod === "Storage") {
            input = {file: inputSettings.S3File}
        } else if (inputSettings.inputMethod === "S3") {
            input = {bucket: inputSettings.S3Bucket, file: inputSettings.S3File}
        }

        const now = new Date();
        const year = String(now.getFullYear()).slice(-2);
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
    
        const currentTime = `${year}${month}${day}_${hours}${minutes}${seconds}`;
        
        const video_name = input.file
        ? input.file.split('/').pop()?.split('.').slice(0, -1).join('.')
        : 'video';

        const output = {
        bucket: 'raptvideo-output',
        path: `${video_name}_${currentTime}`
        }
    
        type EncodingItem = {
        bitrateMb?: number;
        bitrateKb?: number;
        width?: number;
        height?: number;
        fps?: number;
        } | {
        subtitle: string;
        input?: {
            ingest: string
        } | {
            file: string
        } | {
            bucket: string,
            file: string
        }
        } | {
        audio: string;
        input?: {
            ingest: string
        } | {
            file: string
        } | {
            bucket: string,
            file: string
        }
        };
    
        const encoding: EncodingItem[] = bitrateLadder.map((ladder) => {
        const result: { bitrateMb?: number; bitrateKb?: number; width?: number; height?: number; fps?: number } = {};
        
        if (ladder.bitrate && ladder.bitrate > 1000) result.bitrateMb = ladder.bitrate / 1000;
        if (ladder.bitrate && ladder.bitrate < 1000) result.bitrateKb = ladder.bitrate;
        if (ladder.width) result.width = ladder.width;
        if (ladder.height) result.height = ladder.height;
        if (ladder.framerate) result.fps = ladder.framerate;
        
        return result;
        });
    
        if (audioSettings.inputMode === "None") {
        encoding.push({
            audio: "none"
        });
        } else if (audioSettings.inputMode === "External" && audioSettings.inputType === "Ingest" && audioSettings.externalValue != null) {
        encoding.push({
            audio: "external",
            input: {
            ingest: audioSettings.externalValue
            }
        });
        } else if (audioSettings.inputMode === "External" && audioSettings.inputType === "Storage" && audioSettings.externalValue != null) {
        encoding.push({
            audio: "external",
            input: {
            file: audioSettings.externalValue
            }
        });
        } else if (audioSettings.inputMode === "External" && audioSettings.inputType === "S3" && audioSettings.externalS3Bucket != null && audioSettings.externalS3File != null) {
        encoding.push({
            audio: "external",
            input: {
            bucket: audioSettings.externalS3Bucket,
            file: audioSettings.externalS3File
            }
        });
        }
    
        if (subtitleSettings.inputMode === "None") {
        encoding.push({
            subtitle: "none"
        });
        } else if (subtitleSettings.inputMode === "External" && subtitleSettings.inputType === "Ingest" && subtitleSettings.externalValue != null) {
        encoding.push({
            subtitle: "external",
            input: {
            ingest: subtitleSettings.externalValue
            }
        });
        } else if (subtitleSettings.inputMode === "External" && subtitleSettings.inputType === "Storage" && subtitleSettings.externalValue != null) {
        encoding.push({
            subtitle: "external",
            input: {
            file: subtitleSettings.externalValue
            }
        });
        } else if (subtitleSettings.inputMode === "External" && subtitleSettings.inputType === "S3" && subtitleSettings.externalS3Bucket != null && subtitleSettings.externalS3File != null) {
        encoding.push({
            subtitle: "external",
            input: {
            bucket: subtitleSettings.externalS3Bucket,
            file: subtitleSettings.externalS3File
            }
        });
        }
    
        const getFilters = () => {
        const filters = [];
    
        if (mediaFilter.transcription) {
            filters.push({
            type: 'transcription',
            export: true,
            });
        }
    
        if (mediaFilter.sceneDetection) {
            filters.push({
            type: 'scene-detection',
            export: true,
            });
        }
    
        if (mediaFilter.videoCaptioning) {
            filters.push({
            type: 'video-captioning',
            input: {
                ingest: 'scene-detection',
            },
            export: true,
            });
        }
    
        if (mediaFilter.faceRecognition) {
            filters.push({
            type: 'face-recognition',
            input: {
                ingest: 'scene-detection',
            },
            export: true,
            });
        }

        if (mediaFilter.imageOverlay) {
            filters.push({
            type: 'image-overlay',
            input: {
                bucket: 'raptvideo-eu-west-1-test',
                file: 'Logo/logo_light_512.png'
            }
            });
        }

        if (mediaFilter.timeOverlay) {
            filters.push({
            type: 'time-overlay'
            });
        }

        if (mediaFilter.personOverlay) {
            filters.push({
            type: 'person-overlay',
            input: {
                ingest: 'face-recognition',
            }
            });
        }
    
        return filters.length > 0 ? filters : null;
        };
    
        const filters = getFilters();
    
        const json = {
        input,
        output,
        encoding,
        muxing: { type: muxingFormat },
        ...(filters ? { filters } : {})
        };
    
        setJsonData(json);
    }, [inputSettings, mediaFilter, muxingFormat, codec, bitrateLadder, audioSettings, subtitleSettings]);
    
    return (
        <Container fluid className="mt-5">
        <img src="/logo_light_512.png" alt="Logo" className="logo" width="150" height="150" onClick={handleBackClick}/>

        <h1 className="text-center">RaptVideo</h1>
        <Row>
            <Col md={7}>
            <div className="mt-4">
                <h2>Input:</h2>
                <Form>
                    <ToggleButtonGroup
                        type="radio"
                        name="input"
                        value={inputSettings.inputMethod}
                        onChange={(value) => updateInputSettings('inputMethod', value as InputMethod)}
                        className="toggle-group-responsive"
                    >
                        <ToggleButton id="input-sample" variant="light" value="Sample">
                        Sample
                        </ToggleButton>
                        <ToggleButton id="input-storage" variant="light" value="Storage">
                        Storage
                        </ToggleButton>
                        <ToggleButton id="input-s3" variant="light" value="S3">
                        S3
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Form>

                {inputSettings.inputMethod === 'Sample' && (
                    <div className="mt-3">
                        <Form>
                            <ToggleButtonGroup
                                type="radio"
                                name="sampleVideo"
                                value={inputSettings.sampleFile}
                                onChange={(value) => updateInputSettings('sampleFile', value as SampleOption)}
                                className="toggle-group-responsive"
                            >
                                <ToggleButton id="movie-tos" variant="light" value="TearsOfSteel.mov">
                                Tears of Steel
                                </ToggleButton>
                                <ToggleButton id="movie-sintel" variant="light" value="Sintel.mkv">
                                Sintel
                                </ToggleButton>
                                <ToggleButton id="movie-bbb" variant="light" value="BigBuckBunny.mp4">
                                Big Buck Bunny
                                </ToggleButton>
                                <ToggleButton id="movie-fr1" variant="light" value="FaceRecognition-Room-Work-Leave-Drop-Light.mov">
                                Face recognition 1 Pers
                                </ToggleButton>
                                <ToggleButton id="movie-fr2" variant="light" value="FaceRecognition-PersonInRoom-Swap.mov">
                                Face recognition 2 Pers Simple
                                </ToggleButton>
                                <ToggleButton id="movie-fr3" variant="light" value="FaceRecognition-PersonInRoom-Swap-2Person.mov">
                                Face Recognition 2 Pers Complex
                                </ToggleButton>
                                <ToggleButton id="movie-fr4" variant="light" value="FaceRecognition-LongVideo.mov">
                                Face Recognition Long
                                </ToggleButton>

                            </ToggleButtonGroup>
                        </Form>
                    </div>
                )}

                {inputSettings.inputMethod === 'Storage' && (
                    <Form.Group className="mt-3">
                        <Form.Label>File</Form.Label>
                        <Form.Control
                        type="text"
                        placeholder="File"
                        value={inputSettings.S3File}
                        onChange={(e) => updateInputSettings('S3File', e.target.value as String)}
                        />
                    </Form.Group>
                )}

                {inputSettings.inputMethod === 'S3' && (
                    <>
                        <Form.Group className="mt-3">
                        <Form.Label>S3 Bucket</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="S3 Bucket"
                            value={inputSettings.S3Bucket}
                            onChange={(e) => updateInputSettings('S3Bucket', e.target.value as String)}
                        />
                        </Form.Group>
                        <Form.Group className="mt-3">
                        <Form.Label>S3 File</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="S3 File"
                            value={inputSettings.S3File}
                            onChange={(e) => updateInputSettings('S3File', e.target.value as String)}
                        />
                        </Form.Group>
                    </>
                )}
            </div>
                    
            <div className="mt-4">
                <h2>Video Intelligence</h2>
                <Form.Check
                type="switch"
                label="Transcription"
                checked={mediaFilter.transcription}
                onChange={(e) => handleToggleChange('transcription', e.target.checked)}
                />
                <Form.Check
                type="switch"
                label="Scene Detection"
                checked={mediaFilter.sceneDetection}
                onChange={(e) => {
                    handleToggleChange('sceneDetection', e.target.checked);
                }}
                />
                <Form.Check
                type="switch"
                label="Face Recognition (requires Scene Detection)"
                checked={mediaFilter.faceRecognition}
                onChange={(e) => {
                    handleToggleChange('faceRecognition', e.target.checked);
                }}
                />
                <Form.Check
                type="switch"
                label="Video Captioning (requires Scene Detection)"
                checked={mediaFilter.videoCaptioning}
                onChange={(e) => {
                    handleToggleChange('videoCaptioning', e.target.checked);
                }}
                />
                <Form.Check
                type="switch"
                label="Script Extraction (Coming Soon)"
                checked={mediaFilter.scriptExtraction}
                onChange={(e) => handleToggleChange('scriptExtraction', e.target.checked)}
                disabled
                />
                <Form.Check
                type="switch"
                label="Audio Summarization (Coming Soon)"
                checked={mediaFilter.audioSummarization}
                onChange={(e) => handleToggleChange('audioSummarization', e.target.checked)}
                disabled
                />
                <Form.Check
                type="switch"
                label="Video Summarization (Coming Soon)"
                checked={mediaFilter.videoSummarization}
                onChange={(e) => handleToggleChange('videoSummarization', e.target.checked)}
                disabled
                />
            </div>
    
            <div className="mt-4">
                <h2>Analysis</h2>
                <Form>
                {[
                    { label: 'Media Probe (Coming Soon)', state: mediaProbe, setter: setMediaProbe}
                ].map((option, index) => (
                    <Form.Group className="mb-3" key={index}>
                    <Form.Check
                        type="switch"
                        id={`${option.label.toLowerCase().replace(/\s/g, '-')}-switch`}
                        label={option.label}
                        checked={option.state}
                        onChange={() => option.setter(!option.state)}
                        disabled
                    />
                    </Form.Group>
                ))}
                </Form>
            </div>
    
            {/* Encoding Options */}
            <div className="mt-4">
                <h2>Encoding</h2>

                <div className="mt-3">
                    <h4>Filters</h4>
                    <Form.Check
                    type="switch"
                    label="Image Overlay (Coming Soon)"
                    checked={mediaFilter.imageOverlay}
                    onChange={(e) => handleToggleChange('imageOverlay', e.target.checked)}
                    disabled
                    />
                    <Form.Check
                    type="switch"
                    label="Time Overlay"
                    checked={mediaFilter.timeOverlay}
                    onChange={(e) => {
                        handleToggleChange('timeOverlay', e.target.checked);
                    }}
                    />
                    <Form.Check
                    type="switch"
                    label="Person Overlay (requires Face Recognition)"
                    checked={mediaFilter.personOverlay}
                    onChange={(e) => {
                        handleToggleChange('personOverlay', e.target.checked);
                    }}
                    />
                </div>

                <h4 className="mt-3">Muxing Format</h4>
                <Form>
                <ToggleButtonGroup
                    type="radio"
                    name="muxing-format"
                    value={muxingFormat}
                    onChange={handleMuxingFormatChange}
                    className="toggle-group-responsive"
                >

                    <ToggleButton id="muxing-dash"  variant="light" value="dash">
                    Dash
                    </ToggleButton>
                    <ToggleButton id="muxing-progressive" variant="light" value="progressive">
                    Progressive Mp4
                    </ToggleButton>
                    <ToggleButton id="muxing-hls" variant="light" value="hls" disabled>
                    HLS (Coming soon)
                    </ToggleButton>
                    <ToggleButton id="muxing-dash-hls" variant="light" value="dash&hls" disabled>
                    Dash & HLS (Coming soon)
                    </ToggleButton>
                </ToggleButtonGroup>
                </Form>
    
                <h4 className="mt-3">Codec</h4>
                <Form>
                <ToggleButtonGroup
                    type="radio"
                    name="codec"
                    value={codec}
                    onChange={handleCodecChange}
                    className="toggle-group-responsive"
                >
                    <ToggleButton id="codec-h264" variant="light" value="h264">
                    H264
                    </ToggleButton>
                    <ToggleButton id="codec-h265" variant="light" value="h265" disabled>
                    H265 (Coming soon)
                    </ToggleButton>
                    <ToggleButton id="codec-vp9" variant="light" value="vp9" disabled>
                    VP9 (Coming soon)
                    </ToggleButton>
                    <ToggleButton id="codec-av1" variant="light" value="av1" disabled>
                    AV1 (Coming soon)
                    </ToggleButton>
                </ToggleButtonGroup>
                </Form>
            </div>
    
            {/* Bitrate Ladder */}
            <div className="mt-4">
                <h4>Bitrate Ladder</h4>
                <Row className="mb-2">
                    <Col xs={3} md={3}><strong>Bitrate (Kb)</strong></Col>
                    <Col xs={3} md={2}><strong>Width (optional)</strong></Col>
                    <Col xs={3} md={2}><strong>Height (optional)</strong></Col>
                    <Col xs={3} md={2}><strong>Framerate (optional)</strong></Col>
                </Row>

                {bitrateLadder.map((ladder, index) => (
                <Row className="mb-3 bitrate-row" key={index}>
                    <Col xs={3} md={3}>
                    <Form.Control
                        type="number"
                        placeholder="Bitrate (Kb)"
                        value={ladder.bitrate || ''}
                        onChange={(e) => handleBitrateLadderChange(index, 'bitrate', e.target.value)}
                        required
                    />
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Control
                        type="number"
                        placeholder="Width (optional)"
                        value={ladder.width || ''}
                        onChange={(e) => handleBitrateLadderChange(index, 'width', e.target.value)}
                    />
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Control
                        type="number"
                        placeholder="Height (optional)"
                        value={ladder.height || ''}
                        onChange={(e) => handleBitrateLadderChange(index, 'height', e.target.value)}
                    />
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Control
                        type="number"
                        step="0.03"
                        placeholder="Framerate (optional)"
                        value={ladder.framerate || ''}
                        onChange={(e) => handleBitrateLadderChange(index, 'framerate', e.target.value)}
                    />
                    </Col>
                    <Col xs="auto">
                    <Button variant="danger" className="remove-button" onClick={() => removeBitrateLadder(index)}>
                        Remove
                    </Button>
                    </Col>
                </Row>
                ))}
                <Button variant="dark" onClick={addBitrateLadder}>
                Add Rendition
                </Button>
            </div>
    
            {/* Audio and Subtitle Options */}
            <div className="mt-4">
                <h4>Audio</h4>
                <Form>
                <ToggleButtonGroup
                    type="radio"
                    name="audio"
                    value={audioSettings.inputMode}
                    onChange={(value) => updateAudioSetting('inputMode', value as InputMode)}
                    className="toggle-group-responsive"
                >
                    <ToggleButton id="audio-copy" variant="light" value="Copy">
                    Copy
                    </ToggleButton>
                    <ToggleButton id="audio-none" variant="light" value="None">
                    None
                    </ToggleButton>
                    <ToggleButton id="audio-external" variant="light" value="External">
                    External
                    </ToggleButton>
                </ToggleButtonGroup>
                </Form>
    
                {audioSettings.inputMode === 'External' && (
                <div className="mt-3">
                    <h5>Input Type</h5>
                    <Form.Group>
                    <Form.Select
                        aria-label="External audio input type"
                        onChange={(e) => updateAudioSetting('inputType', e.target.value as InputType)}
                    >
                        <option>Select Input Type</option>
                        <option value="Ingest">Ingest</option>
                        <option value="Storage">Storage</option>
                        <option value="S3">S3</option>
                    </Form.Select>
                    </Form.Group>
    
                    {audioSettings.inputType === 'S3' && (
                    <>
                        <Form.Group className="mt-3">
                        <Form.Label>S3 Bucket</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="S3 Bucket"
                            value={audioSettings.externalS3Bucket}
                            onChange={(e) => updateAudioSetting('externalS3Bucket', e.target.value as String)}
                        />
                        </Form.Group>
                        <Form.Group className="mt-3">
                        <Form.Label>S3 File</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="S3 File"
                            value={audioSettings.externalS3File}
                            onChange={(e) => updateAudioSetting('externalS3File', e.target.value as String)}
                        />
                        </Form.Group>
                    </>
                    )}
    
                    {audioSettings.inputType === 'Storage' && (
                    <Form.Group className="mt-3">
                        <Form.Label>File</Form.Label>
                        <Form.Control
                        type="text"
                        placeholder="File"
                        value={audioSettings.externalValue}
                        onChange={(e) => updateAudioSetting('externalValue', e.target.value as String)}
                        />
                    </Form.Group>
                    )}
    
                    {audioSettings.inputType === 'Ingest' && (
                    <Form.Group className="mt-3">
                        <Form.Label>Ingest</Form.Label>
                        <Form.Control
                        type="text"
                        placeholder="Ingest"
                        value={audioSettings.externalValue}
                        onChange={(e) => updateAudioSetting('externalValue', e.target.value as String)}
                        />
                    </Form.Group>
                    )}
                </div>
                )}
    
                <h4 className="mt-3">Subtitle</h4>
                <Form>
                <ToggleButtonGroup
                    type="radio"
                    name="subtitle"
                    value={subtitleSettings.inputMode}
                    onChange={(value) => updateSubtitleSetting('inputMode', value as InputMode)}
                    className="toggle-group-responsive"
                >
                    <ToggleButton id="subtitle-copy" variant="light" value="Copy">
                    Copy
                    </ToggleButton>
                    <ToggleButton id="subtitle-none" variant="light" value="None">
                    None
                    </ToggleButton>
                    <ToggleButton id="subtitle-external" variant="light" value="External">
                    External
                    </ToggleButton>
                </ToggleButtonGroup>
                </Form>
    
                {subtitleSettings.inputMode === 'External' && (
                <div className="mt-3">
                    <h5>Input Type</h5>
                    <Form.Group>
                    <Form.Select
                        aria-label="External subtitle input type"
                        onChange={(e) => updateSubtitleSetting('inputType', e.target.value as InputType)}
                    >
                        <option>Select Input Type</option>
                        <option value="Ingest">Ingest</option>
                        <option value="Storage">Storage</option>
                        <option value="S3">S3</option>
                    </Form.Select>
                    </Form.Group>
    
                    {subtitleSettings.inputType === 'S3' && (
                    <>
                        <Form.Group className="mt-3">
                        <Form.Label>S3 Bucket</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="S3 Bucket"
                            value={subtitleSettings.externalS3Bucket}
                            onChange={(e) => updateSubtitleSetting('externalS3Bucket', e.target.value as String)}
                        />
                        </Form.Group>
                        <Form.Group className="mt-3">
                        <Form.Label>S3 File</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="S3 File"
                            value={subtitleSettings.externalS3File}
                            onChange={(e) => updateSubtitleSetting('externalS3File', e.target.value as String)}
                        />
                        </Form.Group>
                    </>
                    )}
    
                    {subtitleSettings.inputType === 'Storage' && (
                    <Form.Group className="mt-3">
                        <Form.Label>File</Form.Label>
                        <Form.Control
                        type="text"
                        placeholder="File"
                        value={subtitleSettings.externalValue}
                        onChange={(e) => updateSubtitleSetting('externalValue', e.target.value as String)}
                        />
                    </Form.Group>
                    )}
    
                    {subtitleSettings.inputType === 'Ingest' && (
                    <Form.Group className="mt-3">
                        <Form.Label>Ingest</Form.Label>
                        <Form.Control
                        type="text"
                        placeholder="Ingest"
                        value={subtitleSettings.externalValue}
                        onChange={(e) => updateSubtitleSetting('externalValue', e.target.value as String)}
                        />
                    </Form.Group>
                    )}
                </div>
                )}
            </div>
            </Col>
            <Col md={5} className="mt-4" style={{ backgroundColor: '#f8f9fa', padding: '20px' }}>
            <h4>JSON Request</h4>
            <pre>{JSON.stringify(jsonData, null, 2)}</pre>
            </Col>
        </Row>
        <Row>
            <div className="d-flex justify-content-center mt-3 mb-3">
                <Button variant="dark" size="lg" onClick={handleSubmit}>
                    Launch Task
                </Button>
            </div>
        </Row>
        <Button variant="secondary" onClick={handleBackClick} className="mt-2 mb-3">
            Back
        </Button>
        </Container>
    );
};

export default CreateTask;
